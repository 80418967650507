body {
    background-color: black;
    background-image: url("../media/rock_background.jpg");
    background-size: cover;
    background-attachment: fixed;
}

p, h2 {
    color: white;
}


.nav-container {
    position: fixed;
    width: 100%;
    z-index: 1;
    background: rgb(0, 0, 0, 0.7);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom-style: solid;
    border-bottom-color: #7c7c7c;
    border-bottom-width: 2px;
}

.nav-start {
    display: flex;
    align-items: center;
}

.logo {
    margin-left: 10px;
    height: 40px;
    user-select: none;
    cursor: pointer;
}


.burger-icon {
    display: none;
}

.nav-menu {
    display: block;
    list-style-type: none;
    align-items: center;
    margin: 0;
}

.nav-item {
    display: inline-block;
    text-align: center;
}


.nav-link {
    display: block;
    padding-block: 4vh;
    padding-inline: 20px;
    text-decoration: none;
    user-select: none;
    color: #a5a5a5;
    font-weight: 800;
    text-transform: uppercase;
    position: relative;
    transition: 0.3s linear;
    cursor: pointer;

    &:hover {
        background-color: #55555571;
        color: white;
    }
}


.footer-container {
    display: flex;
    width: 100%;
    background-color: #222222;
    overflow: hidden;
}

.vasen {
    margin: 30px;
    padding-right: 40px;
    border-right: 3px solid #7c7c7c;
}

.yhteystiedot {
    color: white;
    list-style-type: none;
}

.yhteystiedot li {
    padding-bottom: 15px;
}

.contact-button {
    border: 1px solid red;
    border-radius: 10px;
    background: none;
    color: white;
    padding: 10px 10px;
    margin-top: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    transition-duration: .5s;
    cursor: pointer;

    &:hover {
        background-color: red;
    }
}

.logot {
    margin: 30px;
    width: 150px;
    transform: translateY(50%);
    margin-bottom: 20vh;
    /*filter: grayscale(100%);*/
}

@media (max-width: 1090px) {
    .nav-container {
        flex-direction: column;
    }

    .logo {
        height: 5vh;
    }

    .nav-start {
        width: 100%;
        justify-content: space-between;
    }

    .nav-start>* {
        margin-right: 3%;
        margin-left: 2%;
    }

    .nav-item {
        width: 100%;
    }


    .footer-container {
        flex-direction: column;
    }

    .vasen {
        border-right: none;
        border-bottom: 3px solid #7c7c7c;
        margin-bottom: 0;
        padding-bottom: 20px;
    }

    .nav-menu {
        flex-direction: column;
        width: 100%;
        margin: 0;
        background-color: #000000;
        border-top-style: solid;
        border-top-color: #7c7c7c;
        border-top-width: 2px;
        padding: 0;
    }

    .burger-icon {
        display: inline;
    }

    .logot {
        width: 100px;
        margin: 0;
        margin-inline: 30px;
        transform: translate(50%, 50%);
    }

    .footer-oikea {
        margin-bottom: 10vh;
    }
}