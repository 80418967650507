.meista-container {
    display: flex;
    min-height: 100vh;
    border-bottom-style: solid;
    border-bottom-color: #7c7c7c;
    border-bottom-width: 2px;
}

.meista-vasen {
    flex: 35%;
    background-image: url("../media/abstract-networking-concept-still-life-arrangement.jpg");
    background-size: cover;
}

.meista-oikea {
    flex: 65%;
    background: rgb(0, 0, 0, 0.7);
}

.meista-oikea * {
    padding-inline: 10%;
}

.meista-oikea h1 {
    margin-top: 10%;
}

@media (max-width: 800px) {
    .meista-container {
      flex-direction: column;
    }
}