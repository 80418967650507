.tuotteet-container {
    display: flex;
    min-height: 100vh;
    border-bottom-style: solid;
    border-bottom-color: #7c7c7c;
    border-bottom-width: 2px;
}


.tuotteet-oikea {
    flex: 55%;
    background: rgb(0, 0, 0, 0.7);
}

.tuotteet-oikea > ul, p {
    margin-inline: 5%;
    margin-bottom: 5%;
    line-height: 25px;
}

.tuotteet-vasen {
    flex: 45%;
    background: linear-gradient(rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)), url("../media/nappaimisto.jpg");
    background-size: cover;
    background-position: center;
}




@media (max-width: 800px) {
    .tuotteet-vasen {
        background-image: none;
        flex: 0%;
    }

    .tuotteet-oikea {
        flex: 100%;
    }

    .tuotteet-container {
        background-image: url("../media/nappaimisto.jpg");
        background-size: cover;
        background-position: center;
    }
}