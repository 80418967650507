.palvelut-container {
    display: flex;
    min-height: 100vh;
    border-bottom-style: solid;
    border-bottom-color: #7c7c7c;
    border-bottom-width: 2px;
}

.palvelut-vasen {
    flex: 55%;
    background: rgb(0, 0, 0, 0.7);
}

.palvelut-vasen > ul, p {
    margin-inline: 5%;
    line-height: 25px;
}



.palvelut-oikea {
    flex: 45%;
    background: linear-gradient(
        rgba(0, 0, 0, 0.3), 
        rgba(0, 0, 0, 0.3)
      ),url("../media/asennus3.jpg");
    background-size: cover;
}

ul, li {
    color: white;
}

@media (max-width: 800px) {
    .palvelut-oikea {
        background-image: none;
        flex: 0%;
    }
    .palvelut-vasen {
        flex: 100%;
    }
    .palvelut-container {
        background-image: url("../media/asennus3.jpg");
        background-size: cover;
    }
}