.contact-container {
    background-color: #171717;
    border: 1px solid red;
    border-radius: 25px;
    padding: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

label {
    display: flex;
    flex-direction: column;
    color: white;
}

.textfield, textarea {
    padding: 12px 20px;
    margin: 10px;
    background-color: #ccc;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.button-container {
    display: flex;
    justify-content: space-evenly;
}